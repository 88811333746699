/*eslint react-hooks/exhaustive-deps:off*/
import React, { Suspense, lazy, useState, useEffect, useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import LoadingPage from '@components/Loading/LoadingPage';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GENERAL_DOMAINS } from '../server/constant';
import { getNormalIdentifiant } from '@utils/_function';
import settingService from '@services/setting.service';
import { connect } from 'react-redux';
import ROUTES from './routes';
import { menuList } from '@pages/config/menu.config';
const Layout = lazy(() => import('@pages/layouts/Layout'));
const PageNotFound = lazy(() => import('@pages/errorPages/pageErreur404'));

const settings = localStorage.getItem('settingsEnterprise');
let settingsEnterprise = JSON.parse(settings);

function App(props) {
  const [isDomain, setIsDomain] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const getIdentifiantByDomain = useCallback(async () => {
    console.log('Loading google domain identifiant');
    dispatch({ type: 'SET_ISLOADING_IDENTIFIANT', payload: true });
    try {
      const domain = window.location.hostname;
      const enterpriseIdentifiant =
        await settingService.getEnterpriseIdentifiantByDomain(domain);
      dispatch({
        type: 'SET_ENTERPRISE_IDENTIFIANT',
        payload: enterpriseIdentifiant,
      });
    } catch (err) {
      console.log('error getting enterpriseIdentifiant: ', err);
    } finally {
      dispatch({ type: 'SET_ISLOADING_IDENTIFIANT', payload: false });
    }
  });

  useEffect(() => {
    localStorage.setItem('source', '');
    const domain = window.location.hostname;
    const isDomain = !GENERAL_DOMAINS.includes(domain);
    setIsDomain(isDomain);
    if (isDomain) {
      getIdentifiantByDomain(domain);
    } else {
      const enterpriseIdentifiant = getNormalIdentifiant(location.pathname);
      dispatch({
        type: 'SET_ENTERPRISE_IDENTIFIANT',
        payload: enterpriseIdentifiant,
      });
    }
    dispatch({ type: 'SET_IS_DOMAIN', payload: isDomain });
    /*eslint no-unused-vars:off*/
  }, []);

  const isHiddenComponent = (path, Component) => () => {
    const enterpriseIdentifiant = getNormalIdentifiant(location.pathname);
    let listMenu = menuList;
    let settingsEnterprise = JSON.parse(
      localStorage.getItem('settingsEnterprise'),
    );
    if (settingsEnterprise.disableInformations)
      listMenu = listMenu.filter((o) => o.id !== 'information');
    if (settingsEnterprise.disableTeam)
      listMenu = listMenu.filter((o) => o.id !== 'equipe');
    if (settingsEnterprise.disableFlexibility)
      listMenu = listMenu.filter(
        (o) => o.id !== 'flexibilite-et-environnement',
      );
    const currentUrl = location.pathname.split('/');
    let route = '';
    if (listMenu.find((el) => el.id === currentUrl[currentUrl.length - 1])) {
      route = currentUrl[currentUrl.length - 1];
    } else {
      route = listMenu?.[0]?.id;
    }
    let defaultPath = `/${enterpriseIdentifiant}/${route}`;
    // eslint-disable-next-line
    return path === '/' ? (
      settingsEnterprise.disableInformations ? (
        <Redirect to={defaultPath} />
      ) : (
        <Component />
      )
    ) : path === '/equipe' || path === '/equipe/:temoignage' ? (
      settingsEnterprise.disableTeam ? (
        <Redirect to={defaultPath} />
      ) : (
        <Component />
      )
    ) : path === '/flexibilite-et-environnement' ? (
      settingsEnterprise.disableFlexibility ? (
        <Redirect to={defaultPath} />
      ) : (
        <Component />
      )
    ) : (
      <Component />
    );
  };

  return (
    <>
      <div>
        <Suspense fallback={<LoadingPage settings={props.settings} />}>
          <Switch>
            {ROUTES.map(({ path, component }) => (
              <RouteWrapper
                exact
                path={`${!isDomain ? '/:enterpriseIdentifiant' : ''}${path}`}
                component={isHiddenComponent(path, component)}
                layout={Layout}
              />
            ))}
            <Route component={PageNotFound} />
          </Switch>
          <NotificationContainer />
        </Suspense>
      </div>
    </>
  );
}

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
const mapStateToProps = (state) => {
  return { settings: state.settings };
};

export default connect(mapStateToProps)(App);
